import { createContext, useContext, useState } from "react";

const ServiceCenterContext = createContext({});

const ServiceProvider = (props) => {
    const [serviceData, setServiceData] = useState({});

    return (
        <ServiceCenterContext.Provider
            {...props}
            value={{
                serviceData,
                setServiceData,
            }}
        />
    );
};

export const useServiceData = () => {
    const { serviceData } = useContext(ServiceCenterContext);
    return serviceData;
};

export const useSetServiceData = () => {
    const { setServiceData } = useContext(ServiceCenterContext);
    return setServiceData;
};

export default ServiceProvider;
