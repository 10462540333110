import stravaBtn from "../../assets/btn_strava_connectwith_orange.png";

const loginLocation = "/.netlify/functions/login";

const StravaConnect = () => {
  const handleStravaConnect = () => {
    window.location = loginLocation;
  };

  return (
    <>
      <p className="description">
        Connect with Strava and we'll determine if you're ready for a service
        based on your riding!
      </p>
      <button className="login-btn" onClick={handleStravaConnect}>
        <img src={stravaBtn} alt="login-strava-logo" />
      </button>
    </>
  );
};

export default StravaConnect;
