import { TextField } from "@material-ui/core";

const ServiceCalendar = ({ selectedBike, onDateSelect }) => {
    return (
        <>
            <label className="calendar-ask" style={{ color: "white" }}>
                {`When was the last time you serviced your ${selectedBike.name}?`}
            </label>
            <br />
            <TextField
                id="datetime-local"
                type="date"
                defaultValue="2017-05-24"
                style={{ marginTop: 10, color: "white" }}
                InputLabelProps={{
                    shrink: true,
                }}
                onChange={onDateSelect}
            />
        </>
    );
};

export default ServiceCalendar;
