import logo from "./assets/suspension-icon.png";
import "./App.css";

import ServiceCenter from "./components";

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" />
                <p>Welcome to "Should I Service"</p>
            </header>
            <ServiceCenter />
        </div>
    );
}

export default App;
