const SECONDS_IN_HOUR = 3600;

export const computeRideTime = (activities, bike) =>
    activities
        .filter((activity) => {
            console.log(activity);
            return activity.gear_id === bike.id;
        })
        .reduce((rideTime, activity) => {
            console.log(rideTime);
            return (rideTime += activity.moving_time);
        }, 0) / SECONDS_IN_HOUR;
