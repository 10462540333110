import React, { Component } from "react";
import ServiceProvider from "./ServiceCenterContext";
import StravaConnect from "./StravaConnect";
import ServiceScheduler from "./ServiceScheduler";

class ServiceCenter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            accessToken: null,
        };
    }

    componentDidMount() {
        const accessToken = new URLSearchParams(window.location.search).get(
            "access_token"
        );

        if (accessToken) {
            this.setState({ accessToken });
        }
    }

    render() {
        const { accessToken } = this.state;

        return (
            <ServiceProvider>
                {this.state.accessToken ? (
                    <ServiceScheduler {...{ accessToken }} />
                ) : (
                    <StravaConnect />
                )}
            </ServiceProvider>
        );
    }
}

export default ServiceCenter;
