import React from "react";
import styled from "styled-components";

const LoadingContainer = styled.div`
  width: 100%;
  height: auto;
  background-color: #282c34;
`;

const Loading = () => (
  <LoadingContainer>
    <img
      src="https://media.giphy.com/media/FnwQerbljjGJW/giphy.gif"
      alt="Loading Animation"
    />
  </LoadingContainer>
);

export default Loading;
